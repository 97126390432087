import {
  GET_CLIENTS_ASYNC,
  GET_CLIENT_FINANCIAL_DETAIL_ASYNC,
  GET_CLIENT_LEGAL_DETAIL_ASYNC,
  LOADING_CLIENTS_GET,
  SET_CLIENT_ID,
  SET_FINANCIAL_STATUS_ASYNC,
  SET_LEGAL_STATUS_ASYNC,
  GET_CLIENT_STATISTICS_ASYNC,
  SEARCH_CLIENTS_ASYNC,
  RESET_DETAIL_DATA,
  SET_NOTIFICATION_ASYNC,
  GET_CLIENT_MONTHLY_DETAIL_ASYNC,
  SET_FILTERS,
  SEARCH_REPORT_DETAIL_ASYNC,
  SET_IS_ONBOARDING,
  SET_SHOW_DETAIL_SECTION,
  SET_CLIENT_LIST_LAST_PAGE,
  SET_IS_PRODUCTS_PENDING,
} from './action-types';
import { AnyAction } from 'redux';

const initialState = {
  clientsLoading: false,
  clientsData: [],
  legalDetail: {
    explainAdmin: '',
    explainWatch: '',
  },
  financialDetail: {},
  clientStatistics: {},
  monthlyDetail: {},
  clientSectionDetail: 1,
  clientIsOnboarding: false,
  clientHavePendingProducts: false,
  filters: {
    currentState: null,
    protocolization: null,
    isVerified: null,
  },
  clientListLastPage: 1,
};

const clients = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_CLIENTS_ASYNC:
      return { ...state, clientsData: payload };
    case SEARCH_CLIENTS_ASYNC:
      return { ...state, clientsData: payload };
    case GET_CLIENT_FINANCIAL_DETAIL_ASYNC:
      return { ...state, financialDetail: payload };
    case GET_CLIENT_LEGAL_DETAIL_ASYNC:
      return { ...state, legalDetail: payload };
    case GET_CLIENT_MONTHLY_DETAIL_ASYNC:
      return { ...state, monthlyDetail: payload };
    case LOADING_CLIENTS_GET:
      return { ...state, clientsLoading: payload };
    case SET_CLIENT_ID:
      return { ...state, id: payload };
    case SET_FINANCIAL_STATUS_ASYNC:
      return { ...state, ...payload };
    case SET_LEGAL_STATUS_ASYNC:
      return { ...state, ...payload };
    case SET_NOTIFICATION_ASYNC:
      return { ...state, ...payload };
    case GET_CLIENT_STATISTICS_ASYNC:
      return { ...state, clientStatistics: { ...payload } };
    case RESET_DETAIL_DATA:
      return {
        ...state,
        legalDetail: { ...initialState.legalDetail },
        financialDetail: { ...initialState.financialDetail },
        monthlyDetail: { ...initialState.monthlyDetail },
      };
    case SET_FILTERS:
      return { ...state, filters: payload };
    case SEARCH_REPORT_DETAIL_ASYNC:
      return { ...state, monthlyDetail: payload };

    case SET_IS_ONBOARDING:
      return { ...state, clientIsOnboarding: payload };
    case SET_SHOW_DETAIL_SECTION:
      return { ...state, clientSectionDetail: payload };
    case SET_CLIENT_LIST_LAST_PAGE:
      return { ...state, clientListLastPage: payload };
    case SET_IS_PRODUCTS_PENDING:
      return { ...state, clientHavePendingProducts: payload };
    default:
      return state;
  }
};

export default clients;
