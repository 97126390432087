import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styles } from './styles';
import { Pagination } from 'components';
import { UsePagination } from 'hooks';
import { palette } from 'theme/contants';
import { getSavingTicketsList } from 'store/tickets/action';

import { useDispatch, useSelector } from 'react-redux';
import { authSelector, ticketsSelector } from 'store/selectors';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';

export const Mailing = () => {
  const { user } = useSelector(authSelector);
  const tickets = useSelector(ticketsSelector);
  const dispatch = useDispatch();

  const { savingTicketsList, ticketsLoading } = tickets;
  const { nextPage, previousPage, page } = UsePagination(
    savingTicketsList?.currentPage ?? 1,
    savingTicketsList?.totalPages,
    getSavingTicketsList,
    { id: user?.id },
  );

  useEffect(() => {
    dispatch(getSavingTicketsList({ id: user?.id, page: 1 }));
  }, []);

  const headers: string[] = [
    'Número de ticket',
    'Fecha ingreso',
    'Fecha finalización',
    'Tipo de correspondencia',
    'Estatus',
  ];

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center'>
      <Box
        width='100%'
        height={'calc(100vh - 11.5rem)'}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          display={'flex'}
          height={'90%'}
          width={'98%'}
          sx={{ overflowY: 'scroll' }}
          margin={'0 1%'}
          flexDirection={'column'}>
          <Table sx={{ bgcolor: palette.white, borderRadius: '1.125rem' }} aria-label='simple table' stickyHeader>
            <TableHead
              sx={{
                width: '52.5rem',
                [`& .${tableCellClasses.root}`]: {
                  padding: '1rem',
                },
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}>
              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    border: 'none',
                    bgcolor: palette.dark,
                    borderRadius: '0.438rem',
                  },
                }}>
                <TableCell align={'left'} width={'100%'} colSpan={12}>
                  <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                    Tickets ({savingTicketsList?.data?.length})
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    borderBottom: `1px solid ${palette.gray}`,
                  },
                }}>
                {headers?.map((item: string, index: number | string) => (
                  <TableCell width={`${100 / headers.length}%`} key={index}>
                    <Typography fontSize={'0.75rem'} fontWeight='700'>
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {savingTicketsList?.data?.length > 0 ? (
                <>
                  {savingTicketsList?.data?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          zIndex: 0,
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell component='th' scope='row'>
                          <Typography sx={styles.rowText}>{row?.ticketNumber}</Typography>
                        </TableCell>

                        <TableCell component='th' scope='row'>
                          <Typography sx={styles.rowText}>{row?.times?.creationDate} </Typography>
                        </TableCell>

                        <TableCell component='th' scope='row'>
                          <Typography sx={styles.rowText}>{row?.times?.finishDate} </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography sx={styles.rowText}>{row?.correspondence?.name} </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Typography sx={[styles.rowText, { display: 'flex', alignItems: 'center' }]}>
                            {row?.status?.name == 'Finalizado' ? 'Finalizado' : 'Pendiente'}{' '}
                            <Box
                              sx={[
                                styles.circle,
                                { bgcolor: row?.status?.name == 'Finalizado' ? palette?.success : palette?.error },
                              ]}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell component='th' scope='row' />
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow
                  sx={{
                    height: '33.594rem',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}>
                  <TableCell align='center' colSpan={12}>
                    {ticketsLoading ? (
                      <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
                    ) : (
                      <>
                        <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                        <Typography color={palette?.dark} variant='subtitle1'>
                          No hay tickets registrados
                        </Typography>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box width={'98%'} position={'absolute'} bottom={0} padding={'0 1%'}>
            <Pagination
              next={nextPage}
              previous={previousPage}
              page={page}
              totalPages={savingTicketsList?.totalPages || 1}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
