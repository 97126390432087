import { all, fork } from 'redux-saga/effects';
import {
  watchLoginUser,
  watchPreRegister,
  watchForgotPassword,
  watchRestorePassword,
  watchUserLogin,
} from './auth/saga';
import {
  watchDeleteProduct,
  watchGetProducts,
  watchSearchProduct,
  watchSendProduct,
  watchUpdateProduct,
  watchUploadImage,
  watchSavingProducts,
  watchGetProductImage,
  watchDeleteProductImage,
  watchUpdateProductStatus,
} from './products/saga';
import {
  watchSendFinancial,
  watchGetFinancial,
  watchUpdateFinancial,
  watchSendLegal,
  watchGetLegal,
  watchGetAdmPositions,
  watchGetWatchPositions,
  watchDeleteFile,
  watchSaveAdmMembersAsync,
  watchGetMembersAdmAsync,
} from './register/saga';
import { watchGetClients } from './clients/saga';
import { watchgGetUser, watchSendUser, watchUpdateUser, watchSearchUser, watchDeleteUser } from './users/saga';
import { watchGetRoles, watchDeleteRoles, watchSearchRole, watchSendRole, watchUpdateRole } from './roles/saga';
import { watchPermissions } from './permissions/saga';
import { watchGetExportable, watchGetUrl } from './intermitence/saga';
import {
  watchGetSegment,
  watchSaveMonthlyUpload,
  watchGetHistorical,
  watchDownloadFile,
  watchGetPreview,
  watchClearSegments,
  watchSearchReport,
  watchGetFinancialHistorical,
  watchSearchFinancialHistorical,
  watchExportFinancialHistorical,
} from './monthlyReport/saga';
import {
  watchgExportReportData,
  watchgGetEmployers,
  watchgGetMunicipalities,
  watchgGetParishes,
  watchgGetReportData,
  watchgGetStates,
} from './reports/saga';
import { watchGetSavingTicketsAsync } from './tickets/saga';

export default function* allSagas() {
  yield all([
    fork(watchLoginUser),
    fork(watchPreRegister),
    fork(watchSendFinancial),
    fork(watchGetFinancial),
    fork(watchUpdateFinancial),
    fork(watchGetProducts),
    fork(watchSendProduct),
    fork(watchDeleteProduct),
    fork(watchUpdateProduct),
    fork(watchGetClients),
    fork(watchSendLegal),
    fork(watchGetLegal),
    fork(watchGetAdmPositions),
    fork(watchGetWatchPositions),
    fork(watchSearchProduct),
    fork(watchUploadImage),
    fork(watchUserLogin),
    fork(watchSavingProducts),
    fork(watchGetProductImage),
    fork(watchgGetUser),
    fork(watchSendUser),
    fork(watchUpdateUser),
    fork(watchGetRoles),
    fork(watchDeleteRoles),
    fork(watchForgotPassword),
    fork(watchRestorePassword),
    fork(watchPermissions),
    fork(watchSavingProducts),
    fork(watchGetProductImage),
    fork(watchSearchUser),
    fork(watchSearchRole),
    fork(watchSendRole),
    fork(watchUpdateRole),
    fork(watchDeleteProductImage),
    fork(watchDeleteUser),
    fork(watchGetUrl),
    fork(watchGetSegment),
    fork(watchSaveMonthlyUpload),
    fork(watchGetHistorical),
    fork(watchDownloadFile),
    fork(watchDeleteFile),
    fork(watchGetPreview),
    fork(watchClearSegments),
    fork(watchSearchReport),
    fork(watchgGetStates),
    fork(watchgGetMunicipalities),
    fork(watchgGetParishes),
    fork(watchgGetReportData),
    fork(watchgGetEmployers),
    fork(watchGetFinancialHistorical),
    fork(watchUpdateProductStatus),
    fork(watchSearchFinancialHistorical),
    fork(watchgExportReportData),
    fork(watchExportFinancialHistorical),
    fork(watchSaveAdmMembersAsync),
    fork(watchGetMembersAdmAsync),
    fork(watchGetExportable),
    fork(watchGetSavingTicketsAsync),
  ]);
}
