import { GET_SAVING_TICKETS_ASYNC, LOADING_TICKETS_GET } from './action-types';

const initialState = {
  savingTicketsList: [],
  ticketsLoading: false,
};

const tickets = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case GET_SAVING_TICKETS_ASYNC:
      return { ...state, savingTicketsList: payload };
    case LOADING_TICKETS_GET:
      return { ...state, ticketsLoading: payload };
    default:
      return state;
  }
};

export default tickets;
