import { combineReducers } from 'redux';
import register from './register/reducer';
import auth from './auth/reducer';
import intermitence from './intermitence/reducer';
import products from './products/reducer';
import users from './users/reducer';
import roles from './roles/reducer';
import clients from './clients/reducer';
import permissions from './permissions/reducer';
import monthlyReport from './monthlyReport/reducer';
import reports from './reports/reducer';
import tickets from './tickets/reducer';

const reducers = combineReducers({
  register,
  auth,
  intermitence,
  products,
  users,
  roles,
  clients,
  permissions,
  monthlyReport,
  reports,
  tickets,
});

export default reducers;
