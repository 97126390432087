import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import { palette } from 'theme/contants';
import { baseUrl } from 'paths';

export const Legitimation = () => {
  return (
    <Box
      sx={styles.main}
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      height='100vh - 5rem'
      bgcolor={palette.light}>
      <Box width={'80%'} margin={'0 1%'}>
        <Typography color={palette.dark} fontWeight={'700'} textAlign={'left'}>
          Módulo de Prevencion a la Legitimacion de Capitales, Financiamiento al Terrorismo y Financiamiento a la
          Proliferacióon de Armas de Destruccion Masiva en el Sector Cajas de Ahorro de Venezuela.
        </Typography>
      </Box>
      <Box
        marginTop={'1rem'}
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        position='relative'>
        <Box
          component={'img'}
          src={`${baseUrl}/resources/legitimation.svg`}
          margin={'0 1%'}
          alt='gob-logo'
          paddingBottom={'1rem'}
          width={'98%'}
        />
      </Box>
    </Box>
  );
};
