import {
  DELETE_PRODUCT,
  GET_PRODUCTS,
  SEND_PRODUCT,
  SET_PRODUCTS_DATA,
  UPDATE_PRODUCT,
  SEARCH_PRODUCT,
  UPLOAD_IMAGE,
  GET_SAVING_PRODUCTS,
  GET_PRODUCT_IMAGE_URL,
  RESET_IMAGE,
  DELETE_IMAGE,
  UPDATE_PRODUCT_STATUS,
} from './action-types';
import { actionObject } from '../../utils';

export const setProductsData = (data: any) => actionObject(SET_PRODUCTS_DATA, data);
export const getProducts = (page: number) => actionObject(GET_PRODUCTS, page);
export const sendProduct = (body: any, onClose: any, page: number) =>
  actionObject(SEND_PRODUCT, { body, onClose, page });
export const deleteProduct = (id: string, onClose: any, page: number) =>
  actionObject(DELETE_PRODUCT, { id, onClose, page });
export const updateProduct = (id: string, body: any, onClose: any, page: number) =>
  actionObject(UPDATE_PRODUCT, { id, body, onClose, page });
export const searchProduct = (name: string, customPage: any) => actionObject(SEARCH_PRODUCT, { name, customPage });
export const uploadImage = (body: any, action: any, key: string) => actionObject(UPLOAD_IMAGE, { body, action, key });
export const getSavingProducts = (body: string) => actionObject(GET_SAVING_PRODUCTS, { body });
export const getProductImage = (body: any) => actionObject(GET_PRODUCT_IMAGE_URL, body);
export const resetImage = () => actionObject(RESET_IMAGE, null);
export const deleteImage = (body: any, page: number) => actionObject(DELETE_IMAGE, { body, page });
export const updateProductStatus = (
  productId: string,
  status: any,
  page: number,
  id: string,
  callback?: ({ ok }: { ok: boolean }) => void,
) => actionObject(UPDATE_PRODUCT_STATUS, { productId, status, page, id, callback });
