import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService, showDialog } from '../../utils';
import { GET_SAVING_TICKETS_ASYNC, GET_SAVING_TICKETS, LOADING_TICKETS_GET } from './action-types';

import { authSelector } from 'store/selectors';

function* getSavingTicketsAsync({ payload }: any) {
  try {
    yield put(actionObject(LOADING_TICKETS_GET, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, status } = yield call(
      FetchService,
      `/tickets/by-saving-bank/${payload?.id}?page=${payload?.page ?? payload?.initalPage}&take=10`,
      'GET',
      null,
      accessToken,
      false,
      true,
    );

    if (data?.result && status === 200) {
      yield put(actionObject(LOADING_TICKETS_GET, false));
      yield put(actionObject(GET_SAVING_TICKETS_ASYNC, data?.result));
    }
  } catch (error) {
    yield put(actionObject(LOADING_TICKETS_GET, false));
    throw new Error('Ha ocurrido un error');
  }
}

export function* watchGetSavingTicketsAsync() {
  yield takeLatest(GET_SAVING_TICKETS, getSavingTicketsAsync);
}
