import {
  GET_CLIENTS,
  GET_CLIENT_FINANCIAL_DETAIL,
  GET_CLIENT_LEGAL_DETAIL,
  GET_CLIENT_MONTHLY_DETAIL,
  SET_CLIENT_ID,
  SET_LEGAL_STATUS,
  SET_FINANCIAL_STATUS,
  GET_CLIENT_STATISTICS,
  SEARCH_CLIENTS,
  RESET_DETAIL_DATA,
  SET_NOTIFICATION,
  SHOW_MESSAGE,
  SET_FILTERS,
  SEARCH_REPORT_DETAIL,
  SET_IS_ONBOARDING,
  SET_SHOW_DETAIL_SECTION,
  SET_CLIENT_LIST_LAST_PAGE,
  SET_IS_PRODUCTS_PENDING,
} from './action-types';
import { actionObject } from '../../utils';

export const getClients = (payload: any) => actionObject(GET_CLIENTS, payload);
export const getClientLegalDetail = (id: any) => actionObject(GET_CLIENT_LEGAL_DETAIL, { id });
export const getClientFinancialDetail = (id: any) => actionObject(GET_CLIENT_FINANCIAL_DETAIL, { id });
export const getClientStatistics = (id: any) => actionObject(GET_CLIENT_STATISTICS, { id });
export const setLegalStatus = (data: any) => actionObject(SET_LEGAL_STATUS, data);
export const setFinancialStatus = (data: any) => actionObject(SET_FINANCIAL_STATUS, data);
export const setClientId = (id: any) => actionObject(SET_CLIENT_ID, { id });
export const searchClients = (
  name: string,
  customPage: any,
  currentStatus: any,
  protocolization: any,
  isVerified: boolean,
) => actionObject(SEARCH_CLIENTS, { name, customPage, currentStatus, protocolization, isVerified });
export const resetDetailData = () => actionObject(RESET_DETAIL_DATA);
export const setNotification = (data: any) => actionObject(SET_NOTIFICATION, data);
export const setShowMessage = () => actionObject(SHOW_MESSAGE);
export const getMonthlyDetail = (id: any, page = 1) => actionObject(GET_CLIENT_MONTHLY_DETAIL, { id, page });
export const setFilters = (payload: any) => actionObject(SET_FILTERS, payload);
export const searchReportDetail = (name: string, id: string, customPage: any) =>
  actionObject(SEARCH_REPORT_DETAIL, { name, id, customPage });

export const setClientIsOnboarding = (payload: any) => actionObject(SET_IS_ONBOARDING, payload);
export const setClientDetailSection = (payload: any) => actionObject(SET_SHOW_DETAIL_SECTION, payload);

export const setClientListLastPage = (payload: number) => actionObject(SET_CLIENT_LIST_LAST_PAGE, payload);

export const setIsProductsPending = (payload: any) => actionObject(SET_IS_PRODUCTS_PENDING, payload);
