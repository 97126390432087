import { FC, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { palette } from 'theme/contants';
import { AccordionType } from './types';

export const OnboardingAccordion: FC<AccordionType> = ({
  title = '',
  collapseContent = [],
  index,
  selectedBorderColor,
  opened,
  disable,
  onChangeParent,
  onChangeSummary,
  reset,
}) => {
  const [summaryTitle, setSummaryTitle] = useState<string>(title);

  useEffect(() => {
    if (reset) {
      setSummaryTitle(title)
    }
  }, [reset])

  return (
    <>
      <Accordion
        expanded={opened}
        square={true}
        disableGutters={true}
        disabled={disable}
        sx={{
          borderTopLeftRadius: '0.438rem',
          borderTopRightRadius: '0.438rem',
          borderLeft: summaryTitle !== '' ? `0.188rem solid ${selectedBorderColor}` : `0.188rem solid transparent`,
        }}
        onChange={() => onChangeParent(index + 1)}>
        <AccordionSummary
          sx={{
            paddingX: '1.5rem',
            paddingY: '0.1rem',
          }}
          expandIcon={<Icon icon={'bi:caret-down-fill'} color={palette?.dark} width={12} />}
          aria-controls={`panel${index}d-content`}
          id={`panel${index}d-header`}>
          <Typography
            sx={{
              display: '-webkit-box',
              textOverflow: 'ellipsis',
              overflowWrap: 'anywhere',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
            fontSize={'0.875rem'}
            fontWeight={'700'}
            color={[palette?.black]}>{`${index + 1}. ${summaryTitle !== '' ? summaryTitle : title}`}</Typography>
        </AccordionSummary>

        {collapseContent?.map((item: any, idx: number | string) => (
          <AccordionDetails
            key={idx}
            onClick={() => {
              setSummaryTitle(item?.description);
              onChangeSummary(item);
            }}
            sx={{
              paddingX: '1.5rem',
              paddingY: '0.5rem',

              '&:hover': {
                cursor: 'pointer',
                backgroundColor: palette.blueHaze,
              },
            }}>
            <Typography fontSize={'1rem'} textAlign={'left'}>
              {item?.description}
            </Typography>
          </AccordionDetails>
        ))}
      </Accordion>
    </>
  );
};
