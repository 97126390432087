import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actionObject, FetchService } from '../../utils';
import {
  DELETE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_ASYNC,
  GET_SAVING_PRODUCTS,
  PRODUCT_LOADING,
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_ASYNC,
  SEND_PRODUCT,
  UPDATE_PRODUCT,
  UPLOAD_IMAGE,
  GET_PRODUCT_IMAGE_URL_ASYNC,
  GET_PRODUCT_IMAGE_URL,
  DELETE_IMAGE,
  UPDATE_PRODUCT_STATUS,
  UPDATE_PRODUCT_STATUS_ASYNC,
} from './action-types';
import { showDialog, parseToPlainNumber } from '../../utils';
import { authSelector } from 'store/selectors';

function* getProductsAsync({ payload }: any) {
  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/product?page=${payload}`,
      'GET',
      null,
      accessToken,
    );

    if (data && statusCode === 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield put(actionObject(GET_PRODUCTS_ASYNC, data));
    }

    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    throw new Error('Ha ocurrido un error');
  }
}

function* sendProductAsync({ payload }: any) {
  const { body, onClose, page } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    body['price'] = parseToPlainNumber(body['price']);
    const { data, statusCode } = yield call(FetchService, '/product', 'POST', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      onClose();
      yield call(getProductsAsync, { payload: page });
      yield call(showDialog, 'Creado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* updateProductAsync({ payload }: any) {
  const { id, body, onClose, page } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    body['price'] = parseToPlainNumber(body['price']);
    const { data, statusCode, response } = yield call(FetchService, `/product/${id}`, 'PUT', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      onClose();
      yield call(getProductsAsync, { payload: page });
      yield call(showDialog, 'Editado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* deleteProductAsync({ payload }: any) {
  const { id, onClose, page, customPage } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/product/delete/${id}`,
      'DELETE',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      onClose();
      yield call(getProductsAsync, { payload: page });
      yield call(showDialog, 'Eliminado exitosamente', 'success');
    }

    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* searchProductAsync({ payload }: any) {
  const { name, customPage } = payload;
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/product/search?page=1&name=${name}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      customPage(1);
      yield put(actionObject(SEARCH_PRODUCT_ASYNC, data));
    }

    if (!data) {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* uploadImageAsync({ payload }: any) {
  const { body, action, key } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode } = yield call(FetchService, '/product/upload', 'POST', body, accessToken, true);

    if (data && statusCode == 200) {
      const { result } = data;
      action(key, result?.key);
      yield put(actionObject(PRODUCT_LOADING, false));
    }

    if (!data) {
      yield call(showDialog, 'Ha ocurrido un error', 'error');
      yield put(actionObject(PRODUCT_LOADING, false));
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getSavingAsync({ payload }: any) {
  const { body } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      `/product/saving/${body?.clientId ?? body?.id}?page=${body?.page ?? 1}`,
      'GET',
      undefined,
      accessToken,
    );

    if (data && statusCode == 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield put(actionObject(GET_PRODUCTS_ASYNC, data));
    }
    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* getProductImageUrl({ payload }: any) {
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      '/product/get-image-url',
      'POST',
      payload,
      accessToken,
    );

    if (data && statusCode == 200) yield put(actionObject(GET_PRODUCT_IMAGE_URL_ASYNC, data?.result?.url));
    if (!data) yield call(showDialog, 'Ha ocurrido un error', 'error');
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* deleteProductImage({ payload }: any) {
  const { body, page } = payload;
  try {
    const {
      user: { accessToken },
    } = yield select(authSelector);
    const { data, statusCode, response } = yield call(
      FetchService,
      '/product/delete-image',
      'DELETE',
      body,
      accessToken,
    );

    if (data && statusCode == 200) yield call(getProductsAsync, { payload: page });
    if (!data) return response?.message;
  } catch (error) {
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

function* updateProductStatusAsync({ payload }: any) {
  const { productId, status, page, id, callback } = payload;

  try {
    yield put(actionObject(PRODUCT_LOADING, true));
    const {
      user: { accessToken },
    } = yield select(authSelector);

    const body = { productStatus: status };

    const { data, statusCode } = yield call(FetchService, `/product/status/${productId}`, 'PUT', body, accessToken);

    if (data && statusCode === 200) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield put(actionObject(UPDATE_PRODUCT_STATUS_ASYNC, data));
      yield call(getSavingAsync, { payload: { body: { clientId: id, page } } });
      callback &&
        callback({
          ok: true,
        });

      yield call(showDialog, 'Estado del producto actualizado', 'success');
    }

    if (!data) {
      yield put(actionObject(PRODUCT_LOADING, false));
      yield call(showDialog, 'Ha ocurrido un error', 'error');
    }
  } catch (error) {
    yield put(actionObject(PRODUCT_LOADING, false));
    yield call(showDialog, 'Ha ocurrido un error', 'error');
  }
}

export function* watchGetProducts() {
  yield takeLatest(GET_PRODUCTS, getProductsAsync);
}

export function* watchSendProduct() {
  yield takeLatest(SEND_PRODUCT, sendProductAsync);
}

export function* watchDeleteProduct() {
  yield takeLatest(DELETE_PRODUCT, deleteProductAsync);
}

export function* watchUpdateProduct() {
  yield takeLatest(UPDATE_PRODUCT, updateProductAsync);
}

export function* watchSearchProduct() {
  yield takeLatest(SEARCH_PRODUCT, searchProductAsync);
}

export function* watchUploadImage() {
  yield takeLatest(UPLOAD_IMAGE, uploadImageAsync);
}

export function* watchSavingProducts() {
  yield takeLatest(GET_SAVING_PRODUCTS, getSavingAsync);
}

export function* watchGetProductImage() {
  yield takeLatest(GET_PRODUCT_IMAGE_URL, getProductImageUrl);
}

export function* watchDeleteProductImage() {
  yield takeLatest(DELETE_IMAGE, deleteProductImage);
}
export function* watchUpdateProductStatus() {
  yield takeLatest(UPDATE_PRODUCT_STATUS, updateProductStatusAsync);
}
