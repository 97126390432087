import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  CircularProgress,
} from '@mui/material';
import { FC } from 'react';
import { Pagination, DownloadButton } from 'components';
import { palette } from 'theme/contants';
import { Icon } from '@iconify/react';
import { styles } from './styles';

export const HistoricalTable: FC<any> = ({
  rowsData = [],
  title,
  headers,
  page,
  nextPage,
  previousPage,
  totalPages,
  loading,
  isAdmin,
}: any) => {
  return (
    <Box
      width='100%'
      height={'calc(100vh - 14rem)'}
      position={'relative'}
      display='flex'
      justifyContent='flex-start'
      flexDirection='column'
      alignItems='center'>
      <Box
        display={'flex'}
        height={'90%'}
        width={'98%'}
        sx={{ overflowY: 'scroll' }}
        margin={'0 1%'}
        position={'relative'}
        flexDirection={'column'}>
        <Table sx={{ bgcolor: palette.white, borderRadius: '1.125rem' }} aria-label='simple table' stickyHeader>
          <TableHead
            sx={{
              width: '52.5rem',
              [`& .${tableCellClasses.root}`]: {
                padding: '1rem',
              },
              top: 0,
              zIndex: 1,
              position: 'sticky',
            }}>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.6rem',
                  paddingBottom: '0.6rem',
                  border: 'none',
                  bgcolor: palette.dark,
                  borderRadius: '0.438rem',
                },
              }}>
              <TableCell align={'left'} width={'100%'} colSpan={12}>
                <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                  {title}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  borderBottom: `1px solid ${palette.gray}`,
                },
              }}>
              {!isAdmin ? (
                <>
                  <TableCell width={'70%'}>
                    <Typography fontSize={'0.75rem'} fontWeight='700'>
                      {headers[0]}
                    </Typography>
                  </TableCell>
                  <TableCell width={'25%'}>
                    <Typography fontSize={'0.75rem'} fontWeight='700'>
                      {headers[1]}
                    </Typography>
                  </TableCell>
                </>
              ) : (
                headers.map((item: any, index: string | number) => {
                  return (
                    <TableCell>
                      <Typography fontSize={'0.75rem'} fontWeight='700'>
                        {item}
                      </Typography>
                    </TableCell>
                  );
                })
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsData?.length > 0 ? (
              <>
                {rowsData?.map((row: any, index: number) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      <Typography fontSize={'0.75rem'} sx={styles.rowText}>
                        {!isAdmin ? row.name : row?.saving_bank_user}
                      </Typography>
                    </TableCell>

                    {isAdmin && (
                      <TableCell component='th' scope='row'>
                        <Typography fontSize={'0.75rem'} sx={styles.rowText}>
                          {row?.saving_bank_sector}
                        </Typography>
                      </TableCell>
                    )}

                    {isAdmin && (
                      <TableCell component='th' scope='row'>
                        <Typography fontSize={'0.75rem'} sx={styles.rowText}>
                          {row?.saving_bank_number_associates}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell component='th' scope='row'>
                      <Box
                        display={'flex'}
                        marginLeft={isAdmin ? '0' : '1rem'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}>
                        <DownloadButton fileName={!isAdmin ? row?.filename : row?.file_filename} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow
                sx={{
                  height: '1rem',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell align='center' colSpan={12}>
                  {loading ? (
                    <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
                  ) : (
                    <>
                      <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                      <Typography color={palette?.dark} variant='subtitle1'>
                        No hay historicos registrados
                      </Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box width={'98%'} position={'absolute'} bottom={0} padding={'0 1%'}>
        <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} />
      </Box>
    </Box>
  );
};
