import { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from '../styles';
import { palette } from 'theme/contants';
import { filters, getYearsInRange, months } from 'utils';
import { AutocompleteSelect, FinancialTable, InputAmount, MuiChips, OnboardingAccordion } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schema } from './validates';
import { getSegments, saveMonthlyUpload, getPreview as getPreviewAction, clearSegments } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { monthlyReportSelector } from 'store/selectors';

const searchByKey: any = {
  group: 'account',
  account: 'first',
  first: 'second',
  second: 'third',
};

export const MonthlyReport = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [currentAccordion, setCurrentAccordion] = useState<number>(-1);
  const [resetAccordions, setResetAccordions] = useState<boolean>(false);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedHead, setSelectedHead] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [nextSegment, setNextSegment] = useState<string>('');
  const monthlyReportData = useSelector(monthlyReportSelector);
  const currentDate = new Date();
  const currentYear: any = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [currentParams, setCurrentParams] = useState<any>({ year: currentYear, month: currentMonth });
  const selectRef = useRef(null);
  const { head, ...data } = monthlyReportData;

  const handlerOnChange = (num: number) => {
    if (num == currentAccordion) return setCurrentAccordion(-1);
    setCurrentAccordion(num);
  };

  const getAccordionsData = (type: string, id: string) => {
    dispatch(getSegments(type, id));
  };

  const getPreview = () => {
    dispatch(getPreviewAction(rowsData));
  };

  const [aviableMonths, setAviableMonths] = useState<any>(months);

  const {
    register,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: '',
      group: '',
      account: '',
      first: '',
      second: '',
      third: '',
      month: currentMonth,
      year: currentYear,
    },
    resolver: yupResolver(schema),
  });

  const handlerSummaryOnChange = (key: any, value: any) => {
    setNextSegment(searchByKey[key]);
    getAccordionsData(searchByKey[key], value?.id);
    setCurrentAccordion(currentAccordion + 1);
    setResetAccordions(false);

    if (key === 'third') setIsDisabled(false);

    return setValue(key, value.id);
  };

  useEffect(() => {
    if (data[nextSegment] && !data[nextSegment].length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data]);

  const isEstimation = (account: any) =>
    account.code[2] === '9' && (account.description.includes('(Estima') || account.description.includes('Estima'));
  const isAmortization = (account: any) =>
    account.code[4] === '9' &&
    account.code[5] === '8' &&
    (account.description.includes('(Amortiza') || account.description.includes('Amortiza'));
  const isDepreciation = (account: any) =>
    account.code[4] === '9' &&
    account.code[5] === '8' &&
    (account.description.includes('(Depre') || account.description.includes('Depre'));
  const isPartials = (account: any) => account.code[5] === '3' && account.description.includes('(Retiro');

  const onSubmit = async () => {
    const values = getValues();
    if (!values.amount) return;
    const amount = values.amount;

    const existHead = head.find((item: any) => item.id === selectedHead.id);
    const existGroup = data.group.find((item: any) => item.id === values.group);
    const existAccount = data.account.find((item: any) => item.id === values.account);
    const existFirst = data.first.find((item: any) => item.id === values.first);
    const existSecond = data.second.find((item: any) => item.id === values.second);
    const existThird = data.third.find((item: any) => item.id === values.third);

    const arrayForRows = [];

    if (existHead) {
      arrayForRows.push({
        ...existHead,
        amount:
          (existAccount && isEstimation(existAccount)) ||
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst))
            ? parseFloat(formattedAmounts(amount)) * -1
            : amount,
        type: 'head',
        mustBeSubstract:
          (existAccount && isEstimation(existAccount)) ||
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst)),
      });
    }
    if (existGroup) {
      arrayForRows.push({
        ...existGroup,
        amount:
          (existAccount && isEstimation(existAccount)) ||
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst))
            ? parseFloat(formattedAmounts(amount)) * -1
            : amount,
        type: 'group',
        mustBeSubstract:
          (existAccount && isEstimation(existAccount)) ||
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst)),
      });
    }
    if (existAccount) {
      arrayForRows.push({
        ...existAccount,
        amount:
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst))
            ? parseFloat(formattedAmounts(amount)) * -1
            : amount,
        type: 'account',
        mustBeSubstract:
          (existFirst && isAmortization(existFirst)) ||
          (existFirst && isDepreciation(existFirst)) ||
          (existFirst && isPartials(existFirst)),
      });
    }
    if (existFirst) {
      arrayForRows.push({
        ...existFirst,
        amount: amount,
        type: 'first',
      });
    }
    if (existSecond) {
      arrayForRows.push({
        ...existSecond,
        amount,
        type: 'second',
      });
    }
    if (existThird) {
      arrayForRows.push({
        ...existThird,
        amount,
        type: 'third',
      });
    }

    setRowsData([...rowsData, ...arrayForRows]);
    reset({
      group: '',
      amount: '',
      account: '',
      first: '',
      second: '',
      third: '',
      month: values.month,
      year: values.year,
    });
    resetAccordion();
    setNextSegment('group');
    setIsDisabled(true);
  };

  useEffect(() => {
    dispatch(getSegments('head'));

    return () => {
      dispatch(clearSegments());
    };
  }, []);

  // Formatear los montos
  const formattedAmounts = (val: any) =>
    typeof val === 'string' ? parseFloat(val.replace(/\./g, '').replace(',', '.')) : val;

  // Reducir y acumular datos
  const reducedData = () => {
    const accumulator: any = {};

    rowsData.forEach((row) => {
      const formattedAmount = formattedAmounts(row.amount);
      if (accumulator[row.id]) {
        accumulator[row.id].amount += formattedAmount;
      } else {
        accumulator[row.id] = { ...row, amount: formattedAmount };
      }
    });

    return Object.values(accumulator);
  };

  // Ordenar los datos de la tabla
  const sortData = (data: any, ascending = true) => {
    return data.sort((a: any, b: any) => (ascending ? a.code.localeCompare(b.code) : b.code.localeCompare(a.code)));
  };

  // Formatear los datos de la tabla
  const formattedRows = () => {
    const sorttedRowsData = sortData(reducedData(), true);
    setRowsData([...sorttedRowsData]);
  };

  // Actualizar los datos de la tabla
  useEffect(() => {
    formattedRows();
  }, [JSON.stringify(rowsData)]);

  // Función recursiva para restar el valor de un row eliminado en todos los niveles superiores
  const subtractFromAncestors = (row: any, rows: any) => {
    let currentParentId = row.parent_id;

    while (currentParentId) {
      const parent = rows.find((r: any) => r.id === currentParentId);
      if (parent) {
        parent.amount = formattedAmounts(parent.amount) - formattedAmounts(row.amount);
        currentParentId = parent.parent_id;
      } else {
        break;
      }
    }
  };

  // Eliminar descendientes huérfanos y ajustar montos
  const removeOrphanDescendants = (parentId: any, rows: any) => {
    const descendants = rows.filter((row: any) => row.parent_id === parentId);
    descendants.forEach((descendant: any) => {
      rows.splice(rows.indexOf(descendant), 1);
      removeOrphanDescendants(descendant.id, rows);
    });
  };

  // Eliminar padres sin hijos y ajustar montos
  const removeEmptyAncestors = (parentId: any, rows: any) => {
    const parent = rows.find((row: any) => row.id === parentId);
    if (parent) {
      const hasChildren = rows.some((row: any) => row.parent_id === parentId);
      if (!hasChildren) {
        rows.splice(rows.indexOf(parent), 1);
        removeEmptyAncestors(parent.parent_id, rows);
      }
    }
  };

  // Función para restar el valor de un row eliminado y eliminar descendientes huérfanos
  const minusValue = (row: any, rowsCopy: any) => {
    // Restar el valor de todos los ancestros
    subtractFromAncestors(row, rowsCopy);

    // Eliminar descendientes huérfanos
    removeOrphanDescendants(row.id, rowsCopy);

    // Eliminar padres sin hijos
    removeEmptyAncestors(row.parent_id, rowsCopy);

    const filteredRows = rowsCopy.filter((rowCopy: any) => rowCopy.amount !== 0);
    setRowsData([...filteredRows]);
  };

  // Función para eliminar un row
  const removeRow = (id: any, row: any) => {
    if (id == undefined) return;
    const rowCopy = [...rowsData];
    rowCopy.splice(id, 1);
    minusValue(row, rowCopy);
  };

  // Funcion para guardar los datos
  const saveMonthly = () => {
    dispatch(saveMonthlyUpload(rowsData, currentParams));
    setRowsData([]);
  };

  const handlerAmount = () => {};

  const resetAccordion = () => {
    setResetAccordions(true);
    getAccordionsData('group', selectedHead?.id);
    setValue('group', '');
    setValue('account', '');
    setValue('first', '');
    setValue('second', '');
    setValue('third', '');
  };

  const handleSelect = (value: any, type: any) => {
    setValue(type, value);
    const filters: any = {
      ...currentParams,
      [type]: type == 'month' ? value?.value : value?.id,
    };

    setCurrentParams(filters);
  };

  const filterAvailableMonths = () => {
    const selectedMonth = currentParams?.month;

    const result: any = months.filter((month) => {
      if (currentParams?.year === currentYear) {
        return parseInt(month.value) <= currentMonth;
      }

      return false;
    });

    if (result?.length == 0) return setAviableMonths(months);
    setAviableMonths(result);

    if (selectedMonth > result[result?.length - 1].value)
      return setCurrentParams({ ...currentParams, month: result[result?.length - 1] });
  };

  useEffect(() => {
    const result: any = months.filter((month: any) => month.value == currentMonth);

    handleSelect(result?.[0], 'month');
    handleSelect({ name: currentYear, id: currentYear }, 'year');
  }, []);

  useEffect(() => {
    filterAvailableMonths();
  }, [currentParams]);

  return (
    <Box sx={styles.main} display='flex' flexDirection='column' alignItems='center'>
      <Box
        width='100%'
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        position='relative'>
        <Box
          width='98%'
          display='flex'
          justifyContent='space-between'
          marginBottom='1rem'
          marginLeft='1%'
          marginRight='1%'
          marginTop='1rem'
          alignItems='center'>
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            {head?.map((item: any, index: number) => {
              const filter = filters.find((filt: any) => filt.value === item.code);
              if (filter) {
                return (
                  <MuiChips
                    key={index}
                    method={() => {
                      setIsDisabled(true);
                      resetAccordion();
                      getAccordionsData('group', item?.id);
                      setNextSegment('group');
                      setSelectedHead(item);
                    }}
                    isRotate={filter?.rotate}
                    icon={filter?.icon}
                    text={filter?.name}
                    isSelected={item == selectedHead ? true : false}
                  />
                );
              }
              return null;
            })}
          </Box>
          <Box display={'flex'}>
            <Box width={'10rem'} marginLeft={'1rem'}>
              <AutocompleteSelect
                {...register('month')}
                label={'Mes'}
                options={aviableMonths}
                disableClearButton
                placeholder={'Seleccionar'}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '15px',
                      border: `1px solid ${palette.gray} !important`,
                    },
                    padding: '4px 14px 4px 4px',
                  },
                }}
                multiple={false}
                control={control}
                handleSelection={(value: any) => {
                  handleSelect(value, 'month');
                }}
                ref={selectRef.current}
                limitTags={1}
              />
            </Box>
            <Box width={'10rem'} marginLeft={'1rem'}>
              <AutocompleteSelect
                {...register('year')}
                label={'Año'}
                options={getYearsInRange(new Date(), 2000)}
                disableClearButton
                placeholder={'Seleccionar'}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '15px',
                      border: `1px solid ${palette.gray} !important`,
                    },
                    padding: '4px 14px 4px 4px',
                  },
                }}
                multiple={false}
                control={control}
                handleSelection={(value: any) => {
                  handleSelect(value, 'year');
                }}
                ref={selectRef.current}
                limitTags={1}
              />
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} height='auto' width={'100%'} justifyContent={'space-between'}>
          <Box
            display={'flex'}
            width={'22.688rem'}
            boxShadow={3}
            flexDirection={'column'}
            justifyContent={'space-between'}
            borderRadius={'0.438rem'}
            bgcolor={palette?.white}
            marginLeft={'1%'}
            marginRight={'1%'}
            position='sticky'
            top='5.6rem'
            height='34.594rem'>
            <Box>
              <OnboardingAccordion
                title='Grupo'
                index={0}
                disable={!data.group.length}
                opened={currentAccordion == 1}
                onChangeParent={handlerOnChange}
                onChangeSummary={(item) => {
                  handlerSummaryOnChange('group', item);
                }}
                selectedBorderColor={palette?.easternBlue}
                collapseContent={data.group || []}
                reset={resetAccordions}
              />
              <OnboardingAccordion
                opened={currentAccordion == 2}
                disable={!data.account.length}
                onChangeParent={handlerOnChange}
                onChangeSummary={(item) => handlerSummaryOnChange('account', item)}
                selectedBorderColor={palette?.funBlue}
                title='Cuenta'
                index={1}
                collapseContent={data.account || []}
                reset={resetAccordions}
              />
              <OnboardingAccordion
                opened={currentAccordion == 3}
                disable={!data.first.length}
                onChangeParent={handlerOnChange}
                onChangeSummary={(item) => handlerSummaryOnChange('first', item)}
                selectedBorderColor={palette?.black}
                title='Primera Subcuenta'
                index={2}
                collapseContent={data.first || []}
                reset={resetAccordions}
              />
              <OnboardingAccordion
                opened={currentAccordion == 4}
                disable={!data.second.length}
                onChangeParent={handlerOnChange}
                onChangeSummary={(item) => handlerSummaryOnChange('second', item)}
                selectedBorderColor={palette?.black}
                title='Segunda Subcuenta'
                index={3}
                collapseContent={data.second || []}
                reset={resetAccordions}
              />
              <OnboardingAccordion
                opened={currentAccordion == 5}
                disable={!data.third.length}
                onChangeParent={handlerOnChange}
                onChangeSummary={(item) => handlerSummaryOnChange('third', item)}
                selectedBorderColor={palette?.black}
                title='Tercera Subcuenta'
                index={4}
                collapseContent={data.third || []}
                reset={resetAccordions}
              />
            </Box>
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <InputAmount
                fullWidth
                {...register('amount')}
                control={control}
                placeholder={'0.00'}
                handlerAmount={handlerAmount}
                sx={{ width: '14.625rem', margin: '0 0.5rem 0.5rem 0.5rem' }}
                showPlaceholder={false}
                error={!!errors?.amount}
                ref={inputRef.current}
                disabled={isDisabled}
              />

              <Button
                type='submit'
                sx={{ width: '5.625rem', margin: '0 0.5rem 0.5rem 0' }}
                onClick={onSubmit}
                variant='contained'
                disabled={isDisabled}>
                Agregar
              </Button>
            </Box>
          </Box>

          <Box width={'98%'} marginLeft={'1%'} marginRight={'1%'}>
            <Box sx={{ minHeight: '34.594rem' }}>
              <FinancialTable rowsData={rowsData} removeRow={removeRow} downloadMonthly={getPreview} />
            </Box>
            <Box
              width={'100%'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              marginTop={'1rem'}
              display={'flex'}
              flex={1}>
              {/* <Button onClick={getPreview}>
                  <Typography sx={{ cursor: 'pointer' }} fontSize={'1rem'} marginRight={'2rem'} fontWeight={'700'}>
                    <IconButton disabled>
                      <Icon icon='heroicons:eye-solid' width={22} color={palette.black} />
                    </IconButton>
                    Ver vista previa
                  </Typography>
                </Button> */}
              <Button
                onClick={saveMonthly}
                type='submit'
                sx={styles.button}
                disabled={rowsData.length === 0}
                variant='contained'>
                Guardar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
