import { palette } from 'theme/contants';
import { Theme } from '@mui/material';

export const styles = {
  rowText: (theme: Theme) => ({
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '0.75rem',
    fontWeight: '700',
  }),
};
