import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  tableCellClasses,
  Box,
  Button,
} from '@mui/material';
import { FC } from 'react';
import { palette } from 'theme/contants';
import { styles } from './styles';
import { Icon } from '@iconify/react';
import { parseAmount, parseToPlainNumber } from 'utils';

export const FinancialTable: FC<any> = ({ rowsData = [], removeRow, downloadMonthly }: any) => {
  return (
    <Table sx={{ bgcolor: palette.white, borderRadius: '1.125rem' }} aria-label='simple table'>
      <TableHead
        sx={{
          width: '52.5rem',
          [`& .${tableCellClasses.root}`]: {
            padding: '1rem',
          },
        }}>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              border: 'none',
              bgcolor: palette.dark,
              borderRadius: '0.438rem',
            },
          }}>
          <TableCell align={'left'} width={'100%'} colSpan={4}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                Datos ingresados{' '}
              </Typography>
              <Button
                onClick={downloadMonthly}
                sx={{ height: 'fit-content', padding: 0 }}
                disabled={rowsData?.length === 0}>
                <Icon icon='fa6-solid:download' width={16} color={palette.white} />
                <Typography marginLeft={'1rem'} fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                  Descargar registro
                </Typography>
              </Button>
            </Box>
          </TableCell>
        </TableRow>

        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              borderBottom: `1px solid ${palette.gray}`,
            },
          }}>
          <TableCell width={'20%'}>
            <Typography fontSize={'0.75rem'} fontWeight='700'>
              Código
            </Typography>
          </TableCell>
          <TableCell width={'20%'}>
            <Typography fontSize={'0.75rem'} fontWeight='700'>
              Cuenta
            </Typography>
          </TableCell>
          <TableCell align={'right'} width={'50%'}>
            <Typography fontSize={'0.75rem'} fontWeight='700'>
              Monto
            </Typography>
          </TableCell>
          <TableCell width={'10%'}>
            <Typography fontSize={'0.75rem'} fontWeight='700'></Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {rowsData?.length > 0 ? (
          <>
            {rowsData?.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: row.type === 'group' ? '#E1E5F3' : row.type === 'head' ? '#00126933' : '#FFFFFF',
                  borderLeft: row.type === 'group' ? '3px solid #1494BC' : 0,
                }}>
                <TableCell component='th' scope='row' width={'20%'}>
                  <Typography sx={styles.rowText}>{row.code} </Typography>
                </TableCell>

                <TableCell component='th' scope='row' width={'20%'}>
                  <Typography sx={styles.rowText}>{row.description} </Typography>
                </TableCell>
                <TableCell component='th' scope='row' align={'right'} width={'50%'}>
                  <Typography fontSize='0.85rem' fontWeight='700'>
                    {typeof row.amount === 'number'
                      ? parseAmount(row?.amount?.toString())
                      : parseAmount(parseToPlainNumber(row?.amount?.toString()))}
                  </Typography>
                </TableCell>

                <TableCell component='th' scope='row' align='right'>
                  <IconButton onClick={() => removeRow(index, row)}>
                    <Icon icon='ic:baseline-close' width={22} color={palette.black} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow
            sx={{
              height: '33.594rem',
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell align='center' colSpan={6}>
              <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
              <Typography color={palette?.dark} variant='subtitle1'>
                No hay registro contable
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
