import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  tableCellClasses,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { FC, useState } from 'react';
import { palette } from 'theme/contants';
import { styles } from './styles';
import { Icon } from '@iconify/react';
import { UseIntermitence } from 'hooks';
import { MuiModal, Pagination } from 'components';
import { AddUsersModal } from 'pages/Admin/Users/components';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from 'store/actions';
import { usersSelector, authSelector } from 'store/selectors';
import { PermissionEnum } from 'utils/constants';

export const UsersTable: FC<any> = ({
  rowsData = [],
  title,
  headers,
  page,
  nextPage,
  previousPage,
  totalPages,
}: any) => {
  const { status, switchStatus } = UseIntermitence();
  const { status: deleteStatus, switchStatus: switchDeleteStatus } = UseIntermitence();
  const [currentUser, setCurrentUser] = useState<any>({});
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);
  const {
    user: {
      role: { permissions },
    },
  } = useSelector(authSelector);

  const closeModal = (name: string) => {
    if (name == 'delete') return switchDeleteStatus();
    setCurrentUser('');
    switchStatus();
  };

  const handleOpenDelete = (row: any) => {
    setCurrentUser(row);
    switchDeleteStatus();
  };

  const handleDelete = () => {
    const { id } = currentUser;
    dispatch(deleteUser(id, switchDeleteStatus, page));
  };

  return (
    <>
      <Box
        display={'flex'}
        height={'70%'}
        width={'98%'}
        sx={{ overflowY: 'scroll' }}
        margin={'0 1%'}
        position={'relative'}
        flexDirection={'column'}>
        <Table sx={{ bgcolor: palette.white, borderRadius: '1.125rem' }} aria-label='simple table' stickyHeader>
          <TableHead
            sx={{
              width: '52.5rem',
              [`& .${tableCellClasses.root}`]: {
                padding: '1rem',
              },
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  border: 'none',
                  bgcolor: palette.dark,
                  borderRadius: '0.438rem',
                },
              }}>
              <TableCell align={'left'} width={'100%'} colSpan={12}>
                <Typography fontWeight={'600'} color={palette.white} fontSize={'0.75rem'}>
                  {title}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  borderBottom: `1px solid ${palette.gray}`,
                },
              }}>
              {headers?.map((item: string, index: number | string) => (
                <TableCell width={`${100 / headers.length}%`} key={index}>
                  <Typography fontSize={'0.75rem'} fontWeight='700'>
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsData.length > 0 ? (
              <>
                {rowsData?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        zIndex: 0,
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>
                          {row?.firstname} {row.lastname}{' '}
                        </Typography>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>{row?.email} </Typography>
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        <Typography sx={styles.rowText}>{row?.role?.name} </Typography>
                      </TableCell>
                      <TableCell component='th' scope='row' />

                      <TableCell align='right' component='th' scope='row' height={'100%'}>
                        {permissions.includes(PermissionEnum.EditUser) && (
                          <IconButton
                            sx={{ margin: '0 0.5rem' }}
                            onClick={() => {
                              switchStatus();
                              setCurrentUser(row);
                            }}>
                            <Icon icon='mdi:square-edit-outline' width={22} color={palette.black} />
                          </IconButton>
                        )}
                        {permissions.includes(PermissionEnum.DeleteUser) && (
                          <IconButton sx={{ margin: '0 0.5rem' }} onClick={() => handleOpenDelete(row)}>
                            <Icon icon='mdi:delete' width={22} color={palette.black} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow
                sx={{
                  height: '33.594rem',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell align='center' colSpan={12}>
                  {users?.usersLoading ? (
                    <CircularProgress variant='indeterminate' sx={{ zIndex: 9999, color: palette?.dark }} />
                  ) : (
                    <>
                      <Icon icon={'material-symbols:view-list-rounded'} width={52} color={palette?.dark} />
                      <Typography color={palette?.dark} variant='subtitle1'>
                        No hay usuarios registrados
                      </Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {status && (
          <AddUsersModal
            open={status}
            type={'edit'}
            onClose={() => closeModal('edit')}
            userData={currentUser}
            page={page}
          />
        )}

        {deleteStatus && (
          <MuiModal
            open={deleteStatus}
            width={'43.563rem'}
            height={'14.063rem'}
            title={'¿Deseas eliminar el usuario?'}
            titleAlign={'center'}
            onCancel={() => closeModal('delete')}>
            <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
              <Box marginRight={'1rem'} marginBottom={'1rem'}>
                <Button onClick={() => closeModal('delete')} variant='outlined' type='submit' sx={styles.button}>
                  Cancelar
                </Button>
              </Box>

              <Box marginLeft={'1rem'} marginBottom={'1rem'}>
                <Button onClick={handleDelete} sx={styles.button} variant='contained'>
                  Eliminar
                </Button>
              </Box>
            </Box>
          </MuiModal>
        )}
      </Box>
      <Box width={'98%'} position={'absolute'} bottom={0} padding={'0 1%'}>
        <Pagination next={nextPage} previous={previousPage} page={page} totalPages={totalPages} />
      </Box>
    </>
  );
};
