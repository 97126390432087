import * as Yup from 'yup';
import { amountCharactersRegex } from 'utils';

export const schema = Yup.object().shape({
  amount: Yup.string()
    .required()
    .matches(amountCharactersRegex, 'Este campo no debe tener caracteres numéricos ni caracteres especiales'),

  group: Yup.string().required(),
  account: Yup.string().required(),
  first: Yup.string().required(),
  second: Yup.string().required(),
  third: Yup.string().required(),
  month: Yup.string().notRequired(),
  year: Yup.string().notRequired(),
});
